import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import { HiUsers } from "react-icons/hi2";
import { MdMiscellaneousServices } from "react-icons/md";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import axios from "axios";
import MainServices from "../../services/MainServices";
import { LazyLoader } from "../../assets/loaders";
import { useNavigate } from "react-router-dom";
import dashboardData from "../../assets/data/dashboard.json";
import doughutData from "../../assets/data/doughnutData.json";
import { IoLogInSharp } from "react-icons/io5";
import Heatmap from "../../assets/elements/HeatMap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const generateRandomData = () => {
  return labels.map(() => Math.floor(Math.random() * 100) + 1);
};

const lineOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Roadside Services",
    },
  },
};

const doughnutOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Analytics of Services Status",
    },
  },
};

const Dashboard = () => {
  const [dashboardLoading, setDashboardLoading] = useState(false);

  const [usersCount, setUsersCount] = useState();
  const [services, setServices] = useState([]);
  const [lineData, setLineData] = useState(dashboardData);
  const [doughnutData, setDoughnutData] = useState(doughutData);
  const [dailyLogins, setDailyLogins] = useState();
  const [servicesCount, setServicesCount] = useState();
  const [serviceHeatMapData, setServiceHeatMapData] = useState();
  const [serviceUtilizationCount, setServiceUtilizationCount] = useState();
  const [serviceUtilizationCountByMonth, setServiceUtlizationCountByMonth] =
    useState([]);
  const [serviceUtilizationByState, setServiceUtilizationByState] = useState(
    []
  );
  const {
    getDashboard,
    getDailyLogins,
    getServicesCount,
    getServicesUtilizatinCount,
    getServiceUtlizationByMonth,
    serviceHeatMap,
    getServiceUtilizationByState,
  } = MainServices();
  const navigate = useNavigate();

  //get dashboard services api
  const getDashboardServices = async () => {
    setDashboardLoading(true);
    await axios
      .all([getDashboard()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            console.log(resData);
            setUsersCount(resData.user_count);
            setServices(resData.services);
            setDashboardLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };

  //get daily logins
  const getDailyLoginNumber = async () => {
    setDashboardLoading(true);
    await axios
      .all([getDailyLogins()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            console.log(resData);
            setDailyLogins(resData.login_count);
            setDashboardLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Count");
      });
  };

  //get services count
  const getCountServices = async () => {
    setDashboardLoading(true);
    await axios
      .all([getServicesCount()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            console.log(resData);
            setServicesCount(resData.services_count.monthly_count);
            setDashboardLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Count");
      });
  };

  //get services utlization count
  const getUttilizationCount = async () => {
    setDashboardLoading(true);
    await axios
      .all([getServicesUtilizatinCount()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            console.log(resData);
            setServiceUtilizationCount(
              resData.services_utilization.monthly_utilization_count[0].count
            );
            setDashboardLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving Count");
      });
  };

  //get services utlization count
  const getSericeHeatMap = async () => {
    setDashboardLoading(true);
    try {
      const res = await axios.all([serviceHeatMap()]);
      if (res[0]) {
        let resData = res[0];
        if (resData.success) {
          const filteredData = resData.data.heatmap_data
            ? resData.data.heatmap_data.filter(
                ([lat, lng]) => lat !== null && lng !== null
              )
            : [];
          console.log(filteredData);
          setServiceHeatMapData(filteredData);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.message) console.log("An error occurred in retrieving Count");
    } finally {
      setDashboardLoading(false);
    }
  };

  const getServiceUtlizationCountByMonth = async () => {
    try {
      const res = await axios.all([getServiceUtlizationByMonth()]);
      if (res[0] && res[0].success) {
        const resData = res[0].data || [];

        const formattedData = resData.map((monthData) => {
          return {
            month: monthData.month,
            services: monthData.services.map((service) => ({
              name: service.service,
              count: service.count,
            })),
          };
        });
        setServiceUtlizationCountByMonth(formattedData);
      }
    } catch (error) {
      console.log("An error occurred in retrieving Count:", error);
    }
  };

  const getServiceCountByState = async () => {
    setDashboardLoading(true);
    try {
      const res = await axios.all([getServiceUtilizationByState()]);
      if (res[0] && res[0].success) {
        const resData = res[0].data || [];

        const labels = resData.map((item) => item.state);
        const data = resData.map((item) => item.count);

        setDoughnutData({
          labels,
          datasets: [
            {
              label: "Service Status",
              data,
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
              hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
              ],
            },
          ],
        });

        setServiceUtilizationByState(resData);
      }
    } catch (error) {
      console.log("An error occurred in retrieving Count:", error);
    } finally {
      setDashboardLoading(false);
    }
  };

  useEffect(() => {
    getDashboardServices();
    getDailyLoginNumber();
    getCountServices();
    getUttilizationCount();
    getSericeHeatMap();
    getServiceUtlizationCountByMonth();
    getServiceCountByState();
  }, []);

  const allServices = [
    ...new Set(
      serviceUtilizationCountByMonth
        .flatMap((data) => data.services)
        .map((service) => service.name)
    ),
  ];

  const serviceColors = [
    "rgba(75, 192, 192, 0.6)",  
    "rgba(255, 99, 132, 0.6)",  
    "rgba(54, 162, 235, 0.6)",  
    "rgba(255, 206, 86, 0.6)",  
    "rgba(153, 102, 255, 0.6)",   
  ];

  // Preparing the datasets
  const chartData = {
    labels: allServices,
    datasets: serviceUtilizationCountByMonth.map((data) => {
      return {
        label: data.month,
        data: allServices.map((serviceName) => {
          const service = data.services.find((s) => s.name === serviceName);
          return service ? service.count : 0;
        }),
        backgroundColor: allServices.map(
          (_, serviceIndex) =>
            serviceColors[serviceIndex % serviceColors.length]
        ),
      };
    }),
  };

  const lineOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Year-Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Service Count",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top", // Service names will be displayed at the top
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const chart = legend.chart;
          const meta = chart.getDatasetMeta(index);

          // Toggle visibility of the dataset
          meta.hidden =
            meta.hidden === null ? !chart.data.datasets[index].hidden : null;
          chart.update();
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="main-dashboard">
      {dashboardLoading ? (
        <LazyLoader />
      ) : (
        <>
          <div className="count-container">
            <div className="count-div" onClick={() => navigate("/users-list")}>
              <div className="count-heading">
                <h3>Users Count</h3>
                <div className="icon-container">
                  <HiUsers size={28} color="#41396D" />
                </div>
              </div>
              <h4 style={{ fontSize: "16px" }}>{usersCount}</h4>
            </div>

            <div className="count-div" onClick={() => navigate("/")}>
              <div className="count-heading">
                <h3>Daily Logins</h3>
                <div className="icon-container">
                  <IoLogInSharp size={28} color="#41396D" />
                </div>
              </div>
              <h4 style={{ fontSize: "16px" }}>{dailyLogins}</h4>
            </div>

            <div className="count-div">
              <div className="count-heading">
                <h3>Services Count</h3>
                <div className="icon-container">
                  <MdMiscellaneousServices size={28} color="#41396D" />
                </div>
              </div>
              <h4 style={{ fontSize: "16px" }}>{servicesCount}</h4>
            </div>

            <div className="count-div" onClick={() => navigate("/")}>
              <div className="count-heading">
                <h3>Service Utlization</h3>
                <div className="icon-container">
                  <IoLogInSharp size={28} color="#41396D" />
                </div>
              </div>
              <h4 style={{ fontSize: "16px" }}>{serviceUtilizationCount}</h4>
            </div>
          </div>

          <div className="heatmap-section">
            <h4>Services Heat Map : </h4>
            <Heatmap data={serviceHeatMapData} />
            <p>This map shows the services requested in different locations</p>
          </div>
          <h4
            style={{
              margin: "1.5rem 0 .5rem 0",
              fontSize: "18px",
              fontFamily: "Montserrat-Medium",
            }}
          >
            Roadside Services:
          </h4>
          <div className="count-container">
            {services.map((service, index) => (
              <div
                key={index}
                className="count-div-roadside"
                style={{ marginTop: "0" }}
              >
                <h3
                  style={{ fontSize: "18px", fontFamily: "Montserrat-Medium" }}
                >
                  {service.service}
                </h3>
                <p className="flex no-margin-p">
                  <span>
                    Status:{" "}
                    <span
                      style={{
                        fontFamily: "700",
                        fontFamily: "Montserrat-Medium",
                      }}
                    >
                      {service.StateValue}
                    </span>
                  </span>

                  {service.count}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="charts">
            <div className="line-chart">
              <Bar options={lineOptions} data={chartData} />
            </div>
            <div className="doughnut-chart">
              <Doughnut options={doughnutOptions} data={doughnutData} />
            </div>
          </div> */}
             
            <div
              style={{
                backgroundColor: "#fff",
                height: "500px",
                padding: "2rem", 
                borderRadius: "12px",
                marginTop: "2rem",
              }}
            >
              <Bar options={lineOptions} data={chartData} />
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                height: "300px",
                width: "400px", 
                borderRadius: "12px",
                marginTop: "2rem",
                display: "flex",
                justifyContent: "center",
                padding: ".5rem"
              }}
            >
              <Doughnut options={doughnutOptions} data={doughnutData} />
            </div>
            
        </>
      )}
    </div>
  );
};

export default Dashboard;
