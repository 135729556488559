import React from "react";
import "./Pagination.scss";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";

const Pagination = ({ currentPage, totalPages, paginate }) => {
  const pageNumbers = [];

  if (totalPages <= 10) {
    // Show all pages if total pages are less than or equal to 10
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    // Always show first, last, and some middle pages
    if (currentPage <= 4) {
      for (let i = 1; i <= 6; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...", totalPages);
    } else if (currentPage > totalPages - 4) {
      pageNumbers.push(1, "...");
      for (let i = totalPages - 5; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1, "...");
      for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push("...", totalPages);
    }
  }

  return (
    <div className="pagination-container">
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-button"
      >
        <FaAngleLeft size={22} />
      </button>
      {pageNumbers.map((number, index) =>
        number === "..." ? (
          <span key={index} className="pagination-ellipsis">
            ...
          </span>
        ) : (
          <button
            key={index}
            onClick={() => paginate(number)}
            className={`pagination-button ${
              currentPage === number ? "active" : ""
            }`}
          >
            {number}
          </button>
        )
      )}
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="pagination-button"
      >
        <FaAngleRight size={22} />
      </button>
    </div>
  );
};

export default Pagination;
