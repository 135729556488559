import React, { useState, useEffect } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import Dashboard from "../../containers/dashboard/Dashboard";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";
import Layout from "../layouts/Layout";
import Login from "../../containers/login";

import * as Storage from "../../store/LocalStorage"
import Users from "../../containers/users";
import AppVersion from "../../containers/appVersion/AppVersion";
import Services from "../../containers/dreamtecServices";
import Service from "../../containers/dreamtecServices/service/index"; 
import UserDetails from "../../containers/users/userDetails";
import DreamTechServices from "../../containers/dreamTechServices";
import HomeAssist from "../../containers/homeAssist";
import RoadSideAssistance from "../../containers/roadSideAssistance";
import DriverAssist from "../../containers/driverAssist";
import LegalAssist from "../../containers/legalAssist";
import FinesList from "../../containers/finesList";
import License from "../../containers/renwalLicense/RenwalLicense";

const CustomRoutes = () => {
  const [loading, setLoading] = useState(true);
  const config = useConfig();
  const { admin, updateUserContext } = useAuth();
  const navigate = useNavigate(); 

  const checkSession = () => {
    setLoading(true);
    let session = Storage.get(config.sessionKey);
    session = JSON.parse(session); 
    if (session) {
      updateUserContext(session);
      setLoading(false);
    } else {
      navigate("/login");
      setLoading(false);
    }
  };

  const hasLoaded = config.status === "success" || config.status === "error";

  useEffect(() => {
    if (!hasLoaded) return;
    checkSession();

    // eslint-disable-next-line
  }, [hasLoaded, config]);

 
  return (
    <>
      {admin ? (
        <AuthLayout>
          <Routes>
            <Route path="/*" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users-list" element={<Users />} />
            <Route path="/app-version" element={<AppVersion />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service" element={<Service />} />
            <Route path="/user-details" element={<UserDetails />} />
            <Route path="/dreamtech-services" element={<DreamTechServices />} />
            <Route path="/home-assist" element={<HomeAssist />} />
            <Route path="/roadside-assist" element={<RoadSideAssistance />} />
            <Route path="/driver-assist" element={<DriverAssist />} />
            <Route path="/legal-assist" element={<LegalAssist />} />
            <Route path="/fines" element={<FinesList />} />
            <Route path="/license" element={<License />} />
          </Routes>
        </AuthLayout>
      ) : (
        <Layout>
          <Routes>
            <Route path="/*" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Layout>
      )}
    </>
  );
};

export default CustomRoutes;
