import React, { useEffect, useState } from "react";
import { GoogleMap, HeatmapLayer } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: -26.26219959,
  lng: 28.0229262,
};

const Heatmap = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=visualization`;
      script.onload = () => setIsLoaded(true);
      document.head.appendChild(script);
    } else {
      setIsLoaded(true);
    }
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const heatmapData =
    data && data.length > 0
      ? data.map(([lat, lng, weight]) => ({
          location: new window.google.maps.LatLng(lat, lng),
          weight: weight,
        }))
      : [];

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
      {heatmapData.length > 0 && (
        <HeatmapLayer data={heatmapData} options={{ radius: 30 }} />
      )}
    </GoogleMap>
  );
};

export default Heatmap;
