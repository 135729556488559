const apiendpoint = "https://mcprod.claimtec.co.za";

let apiService = {
  adminLogin: `${apiendpoint}/api/member/verify_pwd`,
  getDailyLogins: `${apiendpoint}/api/admin/daily_logins`,
  getServicesCount: `${apiendpoint}/api/admin/services_count`,
  getServicesUtilizatinCount: `${apiendpoint}/api/admin/services_utilization_count`,
  getServicesDreamtech: `${apiendpoint}/api/admin/get_dreamtech_services`,
  serviceHeatMap: `${apiendpoint}/api/admin/service_heatmap`,
  updateUserStatus: `${apiendpoint}/api/member/update_user_status`,
  getDashboard: `${apiendpoint}/api/dash_board`,
  getUsersList: `${apiendpoint}/api/admin/users_list`,
  getAppVersion: `${apiendpoint}/api/admin/appupdate`,
  updateAppVersion: `${apiendpoint}/api/admin/appupdate`,
  getDreamTecServices: `${apiendpoint}/api/admin/dreamtech_service_list`,
  getUserDetails: `${apiendpoint}/api/admin/get_user_details`,
  getUserMembership: `${apiendpoint}/api/admin/get_user_member`,
  getAllServices: `${apiendpoint}/api/admin/dreamtech_service_list`,

  getFinesList: `${apiendpoint}/api/admin/finespaymentlink_list_admin`,
  getRenwalLicense: `${apiendpoint}/api/admin/renwel_list_admin`,

  getServiceUtlizationByMonth: `${apiendpoint}/api/admin/get_service_utilization_by_month_admin_service`,
  getServiceUtilizationByState: `${apiendpoint}/api/admin/get_service_utilization_by_state`,
};

export default apiService;
