import React, { useState, useEffect } from "react";
import "./RenwalLicense.scss";
import MainServices from "../../services/MainServices";
import axios from "axios";
import { LazyLoader } from "../../assets/loaders";
import { TbEye } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import Pagination from "../../assets/elements/Pagination";

const License = () => {
  const { getRenwalLicense } = MainServices();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleRowClick = (item) => {
    navigate(
      {
        pathname: `/service`,
      },
      { state: item }
    );
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main">
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(row)}>
          <TbEye data-tooltip-id="view-tool-tip" size={20} color="#005478" />
        </div>
      </div>
    );
  };

  const getServices = async () => {
    setLoading(true);
    

    try {
      const res = await axios.all([getRenwalLicense()]);
      if (res[0] && res[0].success) {
        const resData = res[0].data || [];
        setServices(resData);
      }
    } catch (error) {
      console.log("An error occurred in retrieving Services:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentServices = services.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(services.length / itemsPerPage);

  return (
    <div className="main-app-version">
      {loading ? (
        <LazyLoader />
      ) : (
        <>
          <div className="appversion-table">
            {services && services.length > 0 ? (
              <>
                <table border={1}>
                  <thead>
                    <tr>
                      <th className="text-center">S/No</th>
                      <th className="text-center">Membership ID</th>
                      <th className="text-center">Reference</th>
                      <th className="text-center">Service</th>
                      <th className="text-center">SubService</th>
                      <th className="text-center">State Key</th>
                  
                    </tr>
                  </thead>
                  <tbody>
                    {currentServices.map((item, index) => (
                      <tr key={item?.id} className="tr-table">
                        <td className="text-center">
                          {indexOfFirstItem + index + 1}
                        </td>
                        <td className="text-center">
                          {item.membershipId || "-"}
                        </td>
                        <td className="text-center">{item.reference || "-"}</td>
                        <td className="text-center">{item.service || "-"}</td>
                        <td className="text-center">
                          {item.subService || "-"}
                        </td>
                        <td className="text-center">{item.StateKey || "-"}</td>
                    
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={setCurrentPage}
                />
              </>
            ) : (
              <div className="no-users-div">
                <h5 style={{ fontFamily: "Montserrat-Medium" }}>
                  No License Renewal's found!
                </h5>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default License;
