import React, { useState } from "react";
import "./Sidebar.scss";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo/Claimtec-logo.png";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";
import { BiSolidDashboard } from "react-icons/bi";
import * as Storage from "../../store/LocalStorage"; 
import { TbVersions } from "react-icons/tb"; 
import { HiUsers } from "react-icons/hi2";
import { 
  MdMiscellaneousServices,
  MdLogout,
} from "react-icons/md"; 
import { FaAngleDown } from "react-icons/fa6";



export default function Sidebar() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const config = useConfig();
  const [showServicesSubMenu, setShowServicesSubMenu] = useState(false);

  const onLogout = () => {
    logout();
    Storage.remove(config.sessionKey);
    console.log("terminal Cleared");
  };

  return (
    <SimpleBar className="sidebar-main" autoHide={false}>
      <div className="sidebar-logo">
        <img src={Logo} className="crown-img" alt="logo" />
      </div>
      <div className="sidebar-menu-list">
        <div className="each-icon-div">
          <RouterLink id="dashboard" className="each-icon" to="/dashboard">
            <BiSolidDashboard size={24} /> Dashboard
          </RouterLink>
        </div>
        <div className="each-icon-div">
          <RouterLink id="users-list" className="each-icon" to="/users-list">
            <HiUsers size={24} /> Users
          </RouterLink>
        </div>
        <div className="each-icon-div">
          <div
            id="services"
            className={`each-icon ${showServicesSubMenu ? "active" : ""}`}
            onClick={() => setShowServicesSubMenu(!showServicesSubMenu)}
          >
            <MdMiscellaneousServices size={24} /> Services
            <FaAngleDown
              size={20}
              className={`dropdown-icon ${showServicesSubMenu ? "rotate" : ""}`}
            />
          </div>
          {showServicesSubMenu && (
            <div className="submenu">
              <RouterLink
                id="home-assist"
                className="each-icon"
                to="/services"
                style={{ marginTop: "1.2rem" }}
              >
                Service
              </RouterLink>
              <RouterLink
                id="home-assist"
                className="each-icon"
                to="/home-assist"
              >
                Home Assist
              </RouterLink>
              <RouterLink
                id="roadside"
                className="each-icon"
                to="/roadside-assist"
              >
                Roadside
              </RouterLink>
              <RouterLink
                id="driver-assist"
                className="each-icon"
                to="/driver-assist"
              >
                Driver Assist
              </RouterLink>
              <RouterLink
                id="driver-assist"
                className="each-icon"
                to="/legal-assist"
              >
                Legal Assist
              </RouterLink>

              <RouterLink id="fines" className="each-icon" to="/fines">
                Fines
              </RouterLink>
              <RouterLink
                id="licence-renewals"
                className="each-icon"
                to="/license"
              >
                Licence Renewals
              </RouterLink>
              {/*  <RouterLink
                id="service-booking"
                className="each-icon"
                to="/services/service-booking"
              >
                Service Booking
              </RouterLink> */}
            </div>
          )}
        </div>
        {/*   <div className="each-icon-div">
          <RouterLink
            id="app-version"
            className="each-icon"
            to="/dreamtech-services"
          >
            <MdMiscellaneousServices size={24} /> DreamTech
          </RouterLink>
        </div> */}
        <div className="each-icon-div">
          <RouterLink id="app-version" className="each-icon" to="/app-version">
            <TbVersions size={24} /> App Version
          </RouterLink>
        </div>

        <div className="each-icon-div">
          <div
            id="logout"
            style={{ cursor: "pointer" }}
            className="each-icon"
            onClick={onLogout}
          >
            <MdLogout size={24} /> Logout
          </div>
        </div>
      </div>
    </SimpleBar>
  );
}
