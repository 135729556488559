import React from "react";
import "./Header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const { admin } = useAuth();

  const getHeading = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/users-list":
        return "Users";
      case "/app-version":
        return "App Versions";
      case "/services":
        return "Services";
      case "/service":
        return "Detail Service";
      case "/user-details":
        return "User Details";
      case "/admin/orders/order-details":
        return "Order Details";
      case "/admin/users":
        return "Users";
      case "/admin/user-details":
        return "User Details";
      case "/admin/testimonials/add-testimonials":
        return "Add Testimonials";
      case "/admin/settings":
        return "Settings";
      case "/admin/pin-codes":
        return "Pin Codes";
      case "/admin/pin-codes/add-pin-code":
        return "Add PinCode";
      case "/home-assist":
        return "Home Assistance";
      case "/roadside-assist":
        return "Roadside Assistance";
      case "/driver-assist":
        return "Driver Assistance";
      case "/legal-assist":
        return "Legal Assistance";
      case "/fines":
        return "Fines List";
      case "/license":
        return "License List";
      default:
        return "Dashboard";
    }
  };

  return (
    <div className="header-main">
      <div className="left-header">
        <h4>{getHeading()}</h4>
      </div>

      <div className="right-header">
        <div className="image" onClick={() => navigate("/admin/settings")}>
          <h4>{admin?.role}</h4>
        </div>
      </div>
    </div>
  );
}
