import React, { useState, useEffect } from "react";
import "./AppVersion.scss";
import MainServices from "../../services/MainServices";
import axios from "axios";
import { ButtonLoader, LazyLoader } from "../../assets/loaders";
import moment from "moment";
import editicon from "../../assets/images/svg/editicon.svg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik } from "formik";
import ErrorMessage from "../../assets/elements/ErrorMessage";
import * as Yup from "yup";
import { notifySuccess, notifyWarn } from "../../assets/elements/Toaster";
import Pagination from "../../assets/elements/Pagination";

const updateStatusSchema = Yup.object().shape({
  androidVersion: Yup.number().required("Android Version is Required"),
  iosVersion: Yup.number().required("IOS Version is required"),
});

const AppVersion = () => {
  const { getAppVersion, updateAppVersion } = MainServices();

  const [loading, setLoading] = useState(false);
  const [appVersion, setAppVersion] = useState([]);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
   const [currentPage, setCurrentPage] = useState(1);
   const [itemsPerPage] = useState(10);

  const formatDate = (dateString) => {
    return moment(dateString).format("DD MMMM YYYY");
  };

  //modal functions
  const [editModal, setEditModal] = useState(false);
  const [selectedEditStatus, setSelectedEditStatus] = useState([]);

  const toggleEditModal = (row) => {
    setEditModal(!editModal);
    setSelectedEditStatus(row);

    console.log(row);
  };

  const actions = (row) => {
    if (!row) return;
    return (
      <div className="action-btn-main">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggleEditModal(row);
          }}
        >
          <img
            data-tooltip-id="edit-tool-tip"
            src={editicon}
            alt="edit"
            className="action-icon"
          />
        </div>
      </div>
    );
  };

  //get api
  const getAppVersionList = async () => {
    setLoading(true);
    axios
      .all([getAppVersion()])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            setAppVersion(resData);
            setLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in retreving App Version");
      });
  };

  //update api
   const updateVersion = async (values) => {
     setUpdateStatusLoading(true);
     const details = {
       androidVersion: values.androidVersion,
       iosVersion: values.iosVersion
     };

     console.log(details);
     try {
       const res = await axios.all([updateAppVersion(details)]);
       if (res[0]) {
         let message = res[0].message;
         if (res[0].success) {
           notifySuccess("Successfully Updated App Version");
           getAppVersionList();
           setUpdateStatusLoading(false);
           toggleEditModal();
         } else {
           notifyWarn(message);
           setUpdateStatusLoading(false);
           toggleEditModal();
         }
       }
     } catch (error) {
       console.error("Error updating status", error);
     } finally {
       setUpdateStatusLoading(false);
     }
   };

  useEffect(() => {
    getAppVersionList();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAppVersion = appVersion.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(appVersion.length / itemsPerPage); 

  return (
    <div className="main-app-version">
      <h4>App Versions</h4>
      {loading ? (
        <LazyLoader />
      ) : (
        <>
          <div className="appversion-table">
            {appVersion && appVersion.length > 0 ? (
              <>
                <table border={1}>
                  <thead>
                    <tr>
                      <th>S/No</th>
                      <th>Android Version</th>
                      <th>IOS Version</th>

                      <th>Created At</th>
                      <th style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAppVersion.map((item, index) => (
                      <tr key={index} className="tr-table">
                        <td>{index + 1}</td>
                        <td>{item.androidVersion || "-"}</td>
                        <td>{item.iosVersion || "-"}</td>
                        <td>{formatDate(item.created_at) || "-"} </td>
                        <td style={{ textAlign: "center" }}>{actions(item)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={setCurrentPage}
                />
              </>
            ) : (
              <div className="no-users-div">
                <h5 style={{ fontFamily: "Montserrat-Medium" }}>
                  No App Version found!
                </h5>
              </div>
            )}
          </div>
        </>
      )}

      <Modal isOpen={editModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>Edit App Version</ModalHeader>
        <ModalBody>
          <div className="main-modal-div">
            <div className="edit-modal-container">
              <img src={editicon} alt="" />
              <h6>
                Update App Version{" "}
                <span className="edit-prod-name">
                  {selectedEditStatus?.order_id}
                </span>
              </h6>
            </div>
            <Formik
              initialValues={{
                iosVersion: "",
                androidVersion: "",
              }}
              validationSchema={updateStatusSchema}
              onSubmit={(values) => {
                updateVersion(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <div>
                  <div
                    className="status-container"
                    style={{ marginTop: "1rem" }}
                  >
                    <label className="input-headings" htmlFor="fv-topics">
                      Android Version:
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        id="androidVersion"
                        name="androidVersion"
                        placeholder="Android Version"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.androidVersion}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="androidVersion"
                      />
                    </div>
                  </div>

                  <div
                    className="status-container"
                    style={{ marginTop: "1rem" }}
                  >
                    <label className="input-headings" htmlFor="fv-topics">
                      IOS Version:
                    </label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        id="iosVersion"
                        name="iosVersion"
                        placeholder="IOS Version"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.iosVersion}
                      />
                      <ErrorMessage
                        errors={errors}
                        touched={touched}
                        name="iosVersion"
                      />
                    </div>
                  </div>

                  <div className="col-12 mt-2 ">
                    <span className="nk-content p-0">
                      <button
                        className="btn btn-primary"
                        style={{ backgroundColor: "#005478" }}
                        onClick={handleSubmit}
                        type="submit"
                      >
                        <span>
                          {updateStatusLoading ? <ButtonLoader /> : "Update"}
                        </span>
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            style={{ width: "120px" }}
            onClick={toggleEditModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppVersion;
